<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header
      class="d-flex justify-content-between pb-50"
    >
      <b-card-title>{{ title }}</b-card-title>
      <b-link
        v-if="detail"
        class="m-0 p-0 bg-0"
        @click.prevent="detailBox"
      >
        <b-avatar
          size="24"
          :variant="variant"
        >
          <feather-icon
            size="16"
            :icon="icon"
          />
        </b-avatar>
      </b-link>
      <b-avatar
        v-else
        size="24"
        :variant="variant"
      >
        <feather-icon
          size="16"
          :icon="icon"
        />
      </b-avatar>
    </b-card-header>

    <!-- body -->
    <b-card-body>

      <div
        v-for="(browser,index) in value"
        :key="index"
        class="mt-1 d-flex justify-content-between"
      >
        <b-media no-body>
          <b-media-body>
            <h6 class="align-self-center my-auto">
              {{ browser.name }}
            </h6>
          </b-media-body>
        </b-media>
        <span class="font-weight-bold text-body-heading mr-1">{{ browser.total }}</span>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BAvatar, BMedia, BMediaBody, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BAvatar,
    BMedia,
    BMediaBody,
    BLink,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
    detail: {
      type: Boolean,
      default: () => false,
    },
    icon: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: '',
    },
  },
  methods: {
    detailBox() {
      this.$emit('showDetailBox', true)
    },
  },
}
</script>
