<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ title }}</b-card-title>
    </b-card-header>

    <b-card-body>
      <!-- apex chart -->
      <vue-apex-charts
        type="pie"
        class="m-50"
        :options="chartOptions"
        :series="series"
      />

      <!-- list group -->
      <div class="pt-25">
        <div
          v-for="(data,index) in value"
          :key="index"
          class="d-flex justify-content-between"
          :class="index === Object.keys(value).length - 1 ? '':'mb-1'"
        >
          <div class="series-info">
            <span class="font-weight-bolder">{{ data.name }}</span>
          </div>
          <span>{{ data.total }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    VueApexCharts,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: true,
          },
        },
        labels: ['New', 'Returning', 'Referrals'],
        dataLabels: {
          enabled: true,
          FontSize: '7px',
        },
        legend: {
          show: true,
          fontSize: '7px',
          position: 'bottom',
        },
        stroke: {
          width: 4,
        },
        colors: [$themeColors.primary, $themeColors.warning, $themeColors.success, $themeColors.danger, $themeColors.secondary, $themeColors.dark, $themeColors.info],
      },
    }
  },
  watch: {
    value() {
      this.remapData()
    },
  },
  mounted() {
    this.remapData()
  },
  methods: {
    remapData() {
      if (this.value.length !== 0) {
        const keys = []
        const vals = []

        this.value.map(data => {
          keys.push(data.name)
          vals.push(data.total)

          return true
        })
        this.series = vals
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            labels: keys,
          },
        }
      }
    },
  },
}
</script>
