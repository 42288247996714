<template>
  <b-card
    v-if="show"
    no-body
    class="card-revenue-budget"
  >
    <b-card-body>
      <div class="d-sm-flex justify-content-between align-items-center mb-3">
        <h4 class="card-title mb-50 mb-sm-0">
          {{ title }}
        </h4>
      </div>

      <!-- chart -->
      <vue-apex-charts
        id="column-chart"
        type="bar"
        height="350"
        :options="chartOptions"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    date: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      series: [
        {
          name: 'Incoming',
          data: [],
        },
        {
          name: 'On Progress',
          data: [],
        },
        {
          name: 'Closed',
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          stacked: true,
          type: 'bar',
          toolbar: { show: true },
        },
        colors: [$themeColors.primary, $themeColors.warning, $themeColors.success],
        dataLabels: {
          enabled: false,
        },
        grid: {
          padding: {
            top: 10,
            bottom: 10,
          },
        },
        xaxis: {
          type: 'datetime',
          categories: this.date,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '0.86rem',
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            colors: {
              backgroundBarRadius: 10,
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '0.86rem',
            },
          },
        },
      },
    }
  },
  watch: {
    value() {
      this.changeData()
    },
  },
  mounted() {
    this.changeData()
  },
  methods: {
    changeData() {
      this.show = true
      this.series = this.value.series
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: this.value.day,
          },
        },
      }
    },
  },
}
</script>
