<template>
  <section id="dashboard">
    <b-row>
      <b-col class="d-flex justify-content-end mb-1">
        <b-form-datepicker
          id="vi-from"
          v-model="from"
          :max="until"
          :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
          size="sm"
          required
          name="from"
          class="w-25 mr-50"
          placeholder="From"
        />
        <b-form-datepicker
          id="vi-until"
          v-model="until"
          :min="from"
          :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
          size="sm"
          required
          name="until"
          class="w-25 mr-50"
          placeholder="Until"
        />
        <b-button
          type="submit"
          variant="primary"
          size="sm"
          class="mr-50"
          @click="updateTimeline"
        >
          <feather-icon
            size="14"
            icon="SearchIcon"
          />
        </b-button>
        <b-button
          v-if="userData.group_id < 100"
          variant="success"
          size="sm"
          @click="downloadData"
        >
          <feather-icon
            size="14"
            icon="DownloadIcon"
          />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="3"
        md="3"
      >
        <mini-box
          title="All Tickets"
          :value="data.summary.all"
          icon="MailIcon"
          variant="light-primary"
        />
      </b-col>
      <b-col
        xl="3"
        md="3"
      >
        <mini-box
          title="Open Tickets"
          :value="(data.summary['open']) ? data.summary['open'] : 0"
          icon="MessageSquareIcon"
          variant="light-info"
        />
      </b-col>
      <b-col
        xl="3"
        md="3"
      >
        <mini-box
          title="On Hold"
          :value="(data.summary['on-hold']) ? data.summary['on-hold'] : 0"
          icon="PauseCircleIcon"
          variant="light-warning"
        />
      </b-col>
      <b-col
        xl="3"
        md="3"
      >
        <mini-box
          title="Closed Tickets"
          :value="(data.summary.closed) ? data.summary.closed : 0"
          icon="CheckSquareIcon"
          variant="light-success"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        xl="3"
        md="3"
      >
        <pie-box
          title="Ticket By Priority"
          :value="data.priority"
          icon="MailIcon"
          variant="primary"
        />
      </b-col>
      <b-col
        xl="6"
        md="6"
      >
        <column-box
          title="Incoming and Closed Tickets"
          :date="data.daily.day"
          :value="data.daily"
          icon="MessageSquareIcon"
          variant="info"
        />
      </b-col>
      <b-col
        xl="3"
        md="3"
      >
        <pie-box
          title="Ticket By Status"
          :value="data.status"
          icon="CheckSquareIcon"
          variant="success"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        xl="3"
        md="3"
      >
        <list-box
          title="Top Tags"
          icon="TagIcon"
          variant="light-primary"
          :value="data.tags"
          :detail="true"
          @showDetailBox="showModalTags"
        />
      </b-col>
      <b-col
        xl="3"
        md="3"
      >
        <list-box
          title="Channels"
          icon="FolderIcon"
          variant="light-warning"
          :value="data.channel"
          :detail="false"
        />
      </b-col>
      <b-col
        xl="3"
        md="3"
      >
        <list-box
          title="Top Officer"
          icon="UserIcon"
          variant="light-info"
          :value="data.user"
          :detail="true"
          @showDetailBox="showModalDetail"
        />
      </b-col>
      <b-col
        xl="3"
        md="3"
      >
        <list-box
          title="Avg. Handling Time"
          icon="ClockIcon"
          variant="light-success"
          :value="data.channel_time"
          :detail="false"
        />
      </b-col>
      <!-- <b-col
        xl="3"
        md="3"
      >
        <list-box
          title="Customer Feedback"
          icon="StarIcon"
          variant="light-success"
        />
      </b-col> -->
    </b-row>

    <!-- modal -->
    <b-modal
      id="modal-tags-box"
      ref="modal-tags-box"
      title="Tags Distribution"
      size="lg"
      hide-footer
    >
      <vue-good-table
        :columns="tableColumns2"
        :rows="tableData2"
        :group-options="{
          enabled: true
        }"
        :sort-options="{
          enabled: false,
        }"
        style-class="vgt-table"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Name -->
          <span
            v-if="props.column.field === 'fullName'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.fullName }}</span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-modal>

    <b-modal
      id="modal-officer-box"
      ref="modal-officer-box"
      title="Officer Channel Distribution"
      size="xl"
      hide-footer
    >
      <b-card-text>
        <b-table
          ref="refReportListTable"
          class="position-relative"
          :items="tableData"
          responsive
          :fields="tableColumns"
          primary-key="name"
          show-empty
          empty-text="No matching records found"
        />
      </b-card-text>
    </b-modal>
    <!-- modal -->

  </section>
</template>

<script>
import {
  BRow, BCol, BFormDatepicker, BButton, BModal, BCardText, BTable,
} from 'bootstrap-vue'

import { VueGoodTable } from 'vue-good-table'
import { getUserData } from '@/auth/utils'
import moment from 'moment'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import MiniBox from './module/MiniBox.vue'
import ListBox from './module/ListBox.vue'
import PieBox from './module/PieBox.vue'
import ColumnBox from './module/ColumnBox.vue'

import 'vue-good-table/dist/vue-good-table.css'

export default {
  components: {
    VueGoodTable,
    BRow,
    BCol,
    BFormDatepicker,
    BButton,
    BModal,
    BCardText,
    BTable,

    MiniBox,
    ListBox,
    PieBox,
    ColumnBox,
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const lweek = new Date(today.getFullYear(), today.getMonth(), 1)

    return {
      today,
      lweek,
      userData: getUserData(),
      from: moment(lweek).format('YYYY-MM-DD'),
      until: moment(today).format('YYYY-MM-DD'),
      place: {},
      tableData: [],
      tableData2: [],
      data: {
        summary: {},
        channel: [],
        daily: {
          day: [],
          series: [],
        },
        user: [],
        priority: [],
        status: [],
        tags: [],
      },
    }
  },
  setup() {
    const toast = useToast()
    // Table Handlers
    const tableColumns = [
      { key: 'name', label: 'Officer Name' },
      { key: 'Contact Form' },
      { key: 'Direct Call' },
      { key: 'Email' },
      { key: 'Offline' },
      { key: 'Whatsapp' },
      { key: 'Zoom' },
    ]
    const tableColumns2 = [
      { field: 'name', label: 'Tag Name' },
      { field: 'total', label: 'Total' },
    ]
    return {
      toast,
      tableColumns,
      tableColumns2,
    }
  },
  mounted() {
    this.updateTimeline()
  },
  methods: {
    updateTimeline() {
      document.getElementById('loading-bg').style.display = 'block'
      const params = {
        from: this.from,
        until: this.until,
      }

      this.$http.post('dashboard', params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.data = resp.data
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Dashboard data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    downloadData() {
      document.getElementById('loading-bg').style.display = 'block'
      const params = {
        from: this.from,
        until: this.until,
      }

      this.$http.post('dashboard/download', params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Downloading...',
                icon: 'AlertTriangleIcon',
                variant: 'info',
              },
            })

            const link = document.createElement('a')
            link.href = resp.data.file
            link.download = resp.data.file
            link.target = '_blank'
            link.click()
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Dashboard data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    showModalDetail() {
      this.$refs['modal-officer-box'].show()
      this.tableData = this.data.user_channel
    },
    showModalTags() {
      this.$refs['modal-tags-box'].show()
      this.tableData2 = this.data.tags_detail
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
